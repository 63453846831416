import { HttpClient, HttpErrorResponse, HttpEventType } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { catchError, combineLatest, map, Observable, of, throwError } from "rxjs";
import { SharedService } from "../../shared/services/shared.service";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  httpService: HttpClient = inject(HttpClient);
  sharedService: SharedService = inject(SharedService);
  handleError(error: any): Promise<any> {
    console.log(error);
    if (error.status === 200) {
      return Promise.resolve("No data");
    }
    if(error.status === 404){
      return Promise.resolve("No data found");
    }
    // unauthorized - log user out
    // if (error.status === 401) {
    //     localStorage.removeItem('accessToken');
    //     localStorage.removeItem('refreshToken');
    //     localStorage.removeItem('userRoles');
    //     localStorage.removeItem('websiteId');
    //     window.location.href = '#/auth/login';
    // }
    // // not found
    // if (error.status === 404) {
    // }
    // // bad request
    // if (error.status === 400) {
    // }
    // // server error
    // if (error.status === 500) {
    // }
    return Promise.reject(error.message || error);
  }
  private logAndHandleError(error: HttpErrorResponse): Observable<string | never> {
    console.error('An error occurred:', error.message);
   
      return throwError(error.message || 'An unknown error occurred');

  }
  httpGet(url) {
    return this.httpService.get<any[]>(url).pipe(
      map((res: any) => (res.hasOwnProperty("response") ? res.response : res)),
      catchError(this.logAndHandleError.bind(this))
    );
  }
  httpGetWithHeaders(url, params = null) {
    return this.httpService
      .get<any[]>(url, params ? { params: params } : {})
      .pipe(
        map((res: any) =>
          res.hasOwnProperty("response") ? res.response : res
        ),
        catchError(this.logAndHandleError.bind(this))
      );
  }
  httpGetBlob(url) {
    return this.httpService.get(url, { responseType: "blob" as "json" }).pipe(
      map((res: any) => (res.hasOwnProperty("response") ? res.response : res)),
      catchError(this.logAndHandleError.bind(this))
    );
  }
  httpPost(url, payload) {
    return this.httpService.post(url, payload).pipe(
      map((res: any) => {
        if (res.success) {
          this.sharedService.showSnackbar(1, res.message);
        }
        const response = res.hasOwnProperty("response") ? res.response : res;
        return response;
      }),
      catchError(this.logAndHandleError.bind(this))
    );
  }
  httpPostWithOptions(url: string, payload: any, options?: any) {
    return this.httpService.post(url, payload, options ? options : {}).pipe(
      map((res: any) => {
        if (res.success) {
          // this.sharedService.showSnackbar(1, res.message);
        }
        const response = res.hasOwnProperty("response") ? res.response : res;
        return response;
      }),
      catchError(this.logAndHandleError.bind(this))
    );
  }
  httpFilePost(url, file) {
    const formData = new FormData();
    formData.append("file", file);
    return this.httpService
      .post(url, formData, { reportProgress: true, observe: "events" })
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: "progress", message: progress };
            case HttpEventType.Response:
              return event.body;
            default:
              return `Unhandled event: ${event.type}`;
          }
        }),
        catchError(this.logAndHandleError.bind(this))
      );
  }
  httpPut(url, payload) {
    return this.httpService.put(url, payload).pipe(
      map((res: any) => (res.hasOwnProperty("response") ? res.response : res)),
      catchError(this.logAndHandleError.bind(this))
    );
  }
  httpPatch(url, payload) {
    return this.httpService.patch(url, payload).pipe(
      map((res: any) => (res.hasOwnProperty("response") ? res.response : res)),
      catchError(this.logAndHandleError.bind(this))
    );
  }
  httpCombineLatest(urls: string[]) {
    const httpUrls = urls.map((url) => this.httpService.get(url));
    return combineLatest(httpUrls).pipe(
      map((res: any) =>
        res.map((res) => (res.hasOwnProperty("response") ? res.response : res))
      ),
      catchError(this.logAndHandleError.bind(this))
    );
  }
  httpDelete(url: string) {
    return this.httpService.delete(url).pipe(
      map((res: any) => (res.hasOwnProperty("response") ? res.response : res)),
      catchError(this.logAndHandleError.bind(this))
    );
  }
}
